<template>
  <div class="container-enum-single">
    <div class="options-enum-single">
      <div class="option" :class="`op-single-${idx}`" v-for="(option, idx) in question.options" :key="idx" @click="valueChanged('labels', option.label, idx)">
        <i class="material-icons uncheck" :class="`uncheck-${idx}`">radio_button_unchecked</i>
        <p v-html="replaceMeta(option.label)"></p>
      </div>
      <div class="option-other" v-if="question.ui === 'singleOther'">
        <div class="content-other" @click="other()">
          <i class="material-icons uncheck-other" v-if="!isOther">radio_button_unchecked</i>
          <i class="material-icons check-other" v-if="isOther">radio_button_checked</i>
          <p v-html="replaceMeta(question.otherLabel)"></p>
        </div>
        <div class="option-text" v-if="isOther">
          <input class="input-other" type="text" v-model="text" :placeholder="question.placeholder" @keyup="textValue()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceMetadata } from '../../_helpers/metadata'

export default {
  name: 'appsingle',
  props: ['question', 'colors'],
  data () {
    return {
      send: [{ value: null, isActive: false }],
      text: '',
      isOther: false
    }
  },
  mounted () {
    if (this.$route.query.r) {
      this.isOther = true
      const contentOther = document.querySelector('.option-other')
      contentOther.style.border = `1px solid ${this.colors.headerBg}`
      setTimeout(() => {
        document.querySelector('.input-other').focus()
        document.querySelector('.uncheck-other').style.display = 'none'
        document.querySelector('.check-other').style.display = 'flex'
      }, 10)
    }

    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit(this.text)
      }
    })

    if (this.question.randomOptions) {
      const options = this.question.options
      let ctr = options.length
      let temp = ''
      let index = ''
      while (ctr > 0) {
        index = Math.floor(Math.random() * ctr)
        ctr--
        temp = options[ctr]
        options[ctr] = options[index]
        options[index] = temp
      }
      this.question.options = options
    }
  },
  computed: {
    metadata () {
      let data
      if (!this.isPreview) {
        data = this.$store.getters['seedData/getSeedData']
      } else {
        data = this.$store.getters['preview/getSeedData']
      }

      const metadata = data && data.metadata ? data.metadata : {}
      return metadata
    }
  },
  methods: {
    replaceMeta (str) {
      return replaceMetadata(str, this.metadata, this.answersList)
    },
    valueChanged (option, value, idx) {
      this.isOther = false
      const contentOther = document.querySelector('.option-other')

      if (contentOther) {
        contentOther.style.border = '1px solid #e0e0e0'
        this.text = ''
      }

      this.send.splice(0, 1, { value, isActive: true })
      this.submit(value)
    },
    textValue () {
      if (this.question.required) {
        if (this.text.length > 0) {
          this.$root.$emit('show-btn', true)
        } else {
          this.$root.$emit('show-btn', false)
        }
      } else {
        this.$root.$emit('show-btn', true)
      }
    },
    other (idx) {
      this.isOther = !this.isOther
      this.send = [{ value: null, isActive: false }]

      if (this.isOther) {
        this.$root.$emit('show-btn', false)
        setTimeout(() => {
          const contentOther = document.querySelector('.option-other')
          contentOther.style.border = `1px solid ${this.colors.splashColor}`
          document.querySelector('.input-other').focus()
          document.querySelector('.check-other').style.display = 'flex'
        }, 10)
      }

      if (!this.isOther) {
        this.text = ''
        const contentOther = document.querySelector('.option-other')
        contentOther.style.border = '1px solid #e0e0e0'
        this.$root.$emit('show-btn', false)
      }
    },
    async submit (value) {
      let data = {}
      if (this.question.required) {
        if (this.isOther) {
          if (value.length > 0) {
            data = { text: value, value: this.question.otherLabel, isOther: this.isOther }
            this.submitEvents(data)
          }
        }
        if (!this.isOther) {
          data = { value: value, isOther: false, text: '' }
          this.submitEvents(data)
        }
      } else if (!this.question.required && this.isOther) {
        value ? data = { text: value, value: this.question.otherLabel, isOther: this.isOther } : data = { skip: true, isOther: this.isOther }
        this.submitEvents(data)
      } else {
        value ? data = { value: value } : data = { skip: true }
        this.submitEvents(data)
      }
    },
    submitEvents (data) {
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
      this.$root.$emit('refresh-footer')
    }
  }
}
</script>
<style lang="scss">
  .options-enum-single {
    min-height: 370px;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .option, .option-other {
      background: transparent;
      cursor: pointer;
      min-height: 50px;
      width: 95%;
      border-radius: 5px;
      margin: 3px;
      padding: 5px;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        width: 94%;
        font-size: 16px;
        color: var(--splashColor);
        overflow-wrap: break-word;
      }
      .uncheck, .uncheck-other, .check-other {
        color: var(--splashColor);
        font-size: 20px;
        margin-left: 3px;
      }
      .uncheck {
        display: flex;
      }
      .check {
        display: none;
      }
    }
    .option:hover {
      border-color: var(--splashColor);
      transform: scale(1.01);
    }
    .option-other {
      flex-direction: column;
      .content-other {
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 94%;
        }
      }
      .option-text {
        width: 95%;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        input {
          width: 95%;
          height: 100%;
          color: var(--splashColor);
          border-radius: 5px;
          outline: none;
          padding-left: 5px;
          border: 1px solid #e0e0e0;
          font-size: 16px;
        }
      }
    }
    .option-other:hover {
      border-color: var(--splashColor);
    }
  }

// responsivity to mobile
@media only screen and (max-width: 850px) {
  .container-enum-single {
    min-height: var(--body-height) !important;
    .options-enum-single {
      width: 99% !important;
      min-height: var(--body-height) !important;
      .option, .option-other {
        width: 98%;
        border-radius: 1px;
        padding: 5px 0;
        p {
          padding-left: 10px;
          font-size: 15px;
        }
      }
      .option-other {
        .option-text {
          width: 98%;
          input {
            width: 100%;
            border-radius: 1px;
          }
        }
      }
    }
  }
}
</style>
